<template>
  <v-container fluid class="shopping-cart">
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
          ref="table"
          :headers="headers"
          :data="data"
          :config="config"
          :loaded-options="options"
          :total-elements="totalElements"
          :total-pages="totalPages"
          @onChangeData="onChangeData"
          :available-filters.sync="availableFilters"
          :filters-dictionaries="filtersDictionaries"
        >
          <template #customUser>
            <v-autocomplete
              v-model="users"
              :items="userList"
              label="Пользователи"
              multiple
              small-chips
              ref="user"
              placeholder="Введите имя"
              persistent-placeholder
              @update:search-input="getUsers"
              item-value="uuid"
              item-text="generatedName"
              no-data-text="Начните вводить имя"
              deletable-chips
              chips
              return-object
              class="filters__item--autocomplete"
              item-color="#0B583F"
              outlined
              min-height="45"
              hide-details
              @change="onChangeData({userIdList: users.map(i => i.uuid).join(',')})"
            >
            </v-autocomplete>
            <v-icon class="filters__item--autocomplete-close"
                    @click="removeFilter('users')">
              mdi-close-thick
            </v-icon>
          </template>
          <template #customProducts>
            <v-autocomplete
                v-model="products"
                :items="productList"
                label="Товары"
                multiple
                small-chips
                placeholder="Введите товар"
                persistent-placeholder
                @update:search-input="getProducts"
                item-value="id"
                item-text="nameRu"
                chips
                deletable-chips
                no-data-text="Начните вводить наименование товара"
                return-object
                class="filters__item--autocomplete"
                item-color="#0B583F"
                outlined
                hide-details
                @change="onChangeData({productIdList: products.map(i => i.id).join(',')})"
              >
              </v-autocomplete>
            <v-icon class="filters__item--autocomplete-close"
                    @click="removeFilter('products')">
              mdi-close-thick
            </v-icon>
          </template>
        </crm-table-pageable-new>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import { mapActions } from 'vuex';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'productId', sortable: false, text: 'ID', type: 'defaultItem',
        },
        {
          value: 'client.generatedName', sortable: false, text: 'Клиент', type: 'defaultItem',
        },
        {
          value: 'productName', sortable: false, text: 'Товар', type: 'defaultItem',
        },
        {
          value: 'price', sortable: false, text: 'Цена', type: 'defaultItem',
        },
        {
          value: 'amount', sortable: false, text: 'Количество', type: 'defaultItem',
        },
      ],
      data: [],
      orderVisible: false,
      orderId: null,
      config: {
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/shopping-cart-items',
      options: {
        page: 1,
        size: 10,
      },
      users: [],
      products: [],
      userList: [],
      productList: [],
      timeoutId: null,
      timeoutId2: null,
      availableFilters: [
        {
          id: 'users', name: 'Пользователи', type: 'customUser', active: false,
        },
        {
          id: 'products', name: 'Товары', type: 'customProducts', menu: false, active: false,
        },
      ],
      filtersDictionaries: {},
      filterMapper: {
        products: 'productIdList',
        users: 'userIdList',
      },
    };
  },
  computed: {
    statusEnums() {
      return this.$store.getters['entity/getEntity']('statusEnums');
    },
    paymentTypeEnums() {
      return this.$store.getters['entity/getEntity']('paymentTypeEnums');
    },
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    ...mapActions(
      'crm', ['actionSearchUsers', 'actionSearchProducts'],
    ),
    onChangeData(options) {
      let resultOptions = { ...this.options, ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getOrders();
    },
    getOrders(users = this.users, products = this.products) {
      const params = this.$route.query;
      const formattedUrl = this.url;
      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
          userIdList: users.map((i) => i.uuid).join(','),
          productIdList: products.map((i) => i.id).join(','),
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.data = response.content.map((i) => {
          const { client } = i;
          client.generatedName = client.userName || `${client.firstName} ${client.lastName}`;
          return {
            ...i,
            client,
          };
        });
      }).finally(() => {
        this.$loading(false);
      });
    },
    async getUsers(val) {
      if (val) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(async () => {
          const response = await this.actionSearchUsers(val);
          const userList = await this.users.concat(response.content.map((i) => {
            const generatedName = i.username || `${i.firstName}  ${i.lastName}`;
            return {
              ...i,
              generatedName,
            };
          }));
          this.$nextTick(() => {
            this.userList = [...userList];
          });
        }, 500);
      }
    },
    async getProducts(val) {
      if (val) {
        if (this.timeoutId2) {
          clearTimeout(this.timeoutId2);
        }
        this.timeoutId2 = setTimeout(async () => {
          const response = await this.actionSearchProducts(val);
          const productList = this.products.concat(response.content);
          this.$nextTick(() => {
            this.productList = [...productList];
          });
        }, 500);
      }
    },
    reset() {
      this.products = [];
      this.users = [];
      this.productList = [];
      this.userList = [];
      this.onChangeData();
    },
    removeFilter(val) {
      const el = this.availableFilters.find((i) => i.id === val);
      this[val] = [];
      delete this.options[this.filterMapper[val]];
      this.$nextTick(() => {
        el.active = false;
        this.onChangeData(this.options);
      });
    },
  },
};
</script>

<style lang="scss">
.shopping-cart {
  .filters__item--autocomplete {
    .v-select__selections {
      flex-wrap: wrap;
    }
  }
  .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections
  > span {
    width: 100%;
    max-width: 350px;
    min-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex: 1 1 200px;

    .v-chip__content {
      max-width: 95%;
      overflow: hidden;
      button {
        position: absolute;
        right: 10px;
        background-color: #FFFFFF;
      }
    }
  }
}
</style>
